:root {
    --primaryColor: rgba(215,123,90,255);
    --secondaryColor: white;
    --fontSizeTitle: 3rem;
    --fontSizeContent: 1.2rem;
    --fontSizeLabel: 1.2rem;
    --fontSizeDescription: 0.8rem;

    --fontSizeMobile: 0.8rem;
}
/* For desktop*/
body {
    background-color:black;
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
}

@font-face {
    font-family: "Lato";
    src: local("Lato"),
     url('./fonts/Lato/Lato-Regular.ttf') format("truetype");
    font-weight: normal;
   }


div {
    color: var(--secondaryColor);
    letter-spacing: 0.058rem;
    word-spacing: 0.08rem;
    font-family: 'Lato';
}

p {
    color: var(--secondaryColor);
}

li {
    color: var(--secondaryColor);
    font-size: var(--fontSizeContent);
}

.entryAnimation {
    display: flex;
    animation: pulse 6s forwards;
    justify-content: center;
}

@keyframes pulse {
    0% {
        transform: scale(0.9);
        opacity: 0;
    }

    25% {
        transform: scale(1);
        opacity: 1;
    }

    35% {
        transform: scale(1);
        opacity: 1;
    }

    70% {
        transform: scale(0.9);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 0;
        display: none;
    }
}

.entryLogo {
    padding-top: 8rem;
    padding-bottom: 15rem;
    width: 45%;
    height: 75vh;
}

.burgerMenu {
    display: none;
}

.burger {
    display: none;
}

.appBarContainer {
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    position: fixed;
    background-color: rgba(0,0,0,0.8);
    z-index: 1;
}

.appBarContent {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
}

.link {
    color: rgba(215,123,90,255);
    text-decoration: none;
    font-size: var(--fontSizeContent);
}

.header {
    padding-top: 50px;
    display: flex;
    width: 100%;
    padding-bottom: 2rem;
}

.about {
    display: flex;
    width: 50%;
    padding-left: 25%;
    font-size: var(--fontSizeContent);
}

.info {
    display: flex;
    flex-direction: column;
    padding-left: 20%;
    padding-right: 25%;
}

.infoItem {
    display: flex;
    flex-direction: row;
    font-size: var(--fontSizeContent);
    padding: 1rem;
}

.socialMedia {
    min-width: 64px;
    min-height: 64px;
}

#languageButton {
    display: flex;
    justify-content: center;
    align-self: flex-end;
    font-size: 2rem;
    background-color: black;
    color: var(--primaryColor);
    border-color: var(--primaryColor);
    width: 10%;
}

.logo {
    width: 3rem;
    height: 2rem;
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 3.5rem;
    padding-bottom: 0.5rem;
}

.footer div {
    align-self: flex-end;
    color: var(--primaryColor);
    font-size: var(--fontSizeContent);
}

.gmaps {
    display: flex;
    width: 25%;
    border: 0;
}

.grid-scroll {
    display: flex;
}

.frontPictures {
    display: flex;
    flex-direction: column;
}
  
.image-grid {
    display: grid;
    padding-left: 5rem;
    padding-right: 5rem;
    gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(20% , 1fr));
    grid-auto-rows: minmax(450px, auto);
  }

  .image-grid-gallery {
    grid-auto-rows: minmax(600px, auto);
  }

.image-grid2 {
      padding-top: 1rem;
      padding-left: 16rem;
      padding-right: 16rem;
  }

.image-grid3 {
      padding-left: 12rem;
      padding-right: 12rem;
      padding-top: 0.6rem;
  }

.image-grid4 {
      padding-left: 16rem;
      padding-right: 16rem;
      padding-top: 0.6rem;
  }

.image {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.2s transform;
    box-shadow: 1px 1px 5px 0px rgba(215,123,90,255);
    cursor: pointer;
}
  
.imgContainer:nth-child(5n){
    grid-column-end: span 2;
    height: 700px;
  }

.image:hover {
    transform: scale(1.02);
}

.image2 {
    cursor: default;
}

.imageSide {
    box-shadow: none;
    width: 85%;
    height: 85%;
    cursor: default;
    padding-left: 15%;
    align-self:flex-end;
}

.imageSide1 {
    box-shadow: none;
    width: 85%;
    height: 85%;
    cursor: default;
    align-self:flex-end;
}

.imageSide2 {
    box-shadow: none;
    width: 85%;
    height: 85%;
    cursor: default;
    padding-left: 15%;
}

.imageSide3 {
    box-shadow: none;
    width: 85%;
    height: 85%;
    cursor: default;
}

.success {
    display: flex;
    align-self: center;
    width:fit-content;
    color:var(--primaryColor);
    background: black;
    font-size: var(--fontSizeContent);
    border-style: outset;
    border-color: var(--primaryColor);
    border-width: 0.3rem;
    border-radius: 0.5rem;
    padding: 1rem;
  }
  
  .error {
    display: flex;
    width: 15%;
    color: red;
    background: black;
    font-size: var(--fontSizeContent);
    border-style: solid;
    border-radius: 0.5rem;
    padding: 1rem;
  }

.contactContainer {
    padding-top: 5rem;
    display: flex;
    flex-direction: column;
}

.contactTitle {
    text-align: center;
    font-size: var(--fontSizeTitle);
    padding-bottom: 2rem;
}

.checkGallery {
    padding-top: 5rem;
    padding-bottom: 0;
}

.clickGallery {
    text-decoration: none;
    color: var(--primaryColor);
    font-size: var(--fontSizeTitle);
    cursor: pointer;
}

.clickStudio {
    padding-bottom: 1rem;
}

.contactContent {
    display: flex;
    flex-direction: row;
    font-size: var(--fontSizeContent);
    justify-content: space-evenly;
}

.contactText {
    padding-top: 5rem;
    display: flex;
    flex-direction: column;
    width: 25%;
}

.contactSocialMedia {
    display: flex;
    flex-direction: row;
}

.contactForm {
    display: flex;
    flex-direction: column;
	background: transparent;
	border-radius:2px;
	font-family: Georgia, "Times New Roman", Times, serif;
}

.contactForm ul {
	list-style:none;
	padding:0;
	margin:0;	
}

.contactForm li{
	display: block;
	padding: 9px;
	border:1px solid var(--primaryColor);
	margin-bottom: 30px;
	border-radius: 3px;
}

.contactForm li:last-child{
	border:none;
	margin-bottom: 0px;
	text-align: center;
}

.contactForm li > label{
	display: block;
	float: left;
	margin-top: -19px;
	background: black;
	height: 22px;
	padding: 0;
	color: #B9B9B9;
	font-size: var(--fontSizeLabel);
	overflow: hidden;
	font-family: Arial, Helvetica, sans-serif;
}

.contactForm li > span{
	background: black;
	display: block;
	padding: 3px;
	margin: 0 -9px -9px -9px;
	text-align: center;
	color: #C0C0C0;
	font-family: Arial, Helvetica, sans-serif;
	font-size: var(--fontSizeDescription);
}

.contactForm input, textarea {
    background-color: black;
    color: white;
    border: 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    width: 100%;
    display: block;
    outline: none;

    height: 25px;
    line-height: 25px;
    font-size: 16px;
    padding: 0;
    font-family: Georgia, "Times New Roman", Times, serif; 
}

.contactForm input:hover, textarea:hover {
    background-color: var(--primaryColor);
}

.contactForm input:focus, textarea:focus {
    background-color: black;
}

.contactForm textarea {
	resize:none;
}

.contactForm input[type='submit'] {
    background: black;
	border: none;
	border-bottom: 1px solid var(--primaryColor);
	border-radius: 1px;
	color: #D2E2FF;
}

.contactForm input[type='submit']:hover {
	background: var(--primaryColor);
	color:black;
}

.studioCol {
  width: 85%;
  display: flex;
  flex-direction: column;
  padding-left: 9rem;
}

.studioRow {
    padding-top: 1rem;
    flex-direction: row;
    display: flex;
}

.studioImageDivider {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

/* Tablets */
@media only screen and (max-width: 1366px) {

    .image-grid {
        display: grid;
        padding-left: 10rem;
        padding-right: 10rem;
        gap: 14px;
        grid-template-columns: repeat(auto-fill, minmax(200px , 1fr));
        grid-auto-rows: minmax(250px, auto);
      }

      .image {
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.2s transform;
        box-shadow: 1px 1px 5px 0px rgba(215,123,90,255);
        cursor: pointer;
    }

    .image-grid2 {
        padding-top: 1rem;
        padding-left: 16rem;
        padding-right: 16rem;
    }
  
  .image-grid3 {
        padding-left: 12rem;
        padding-right: 12rem;
        padding-top: 0.2rem;
    }
  
  .image-grid4 {
        padding-left: 16rem;
        padding-right: 16rem;
        padding-top: 0.2rem;
    }

    .imageSide {
        box-shadow: none;
    }

    .imageSide1 {
        box-shadow: none;
    }

    .imageSide2 {
        box-shadow: none;
    }

    .imageSide3 {
        box-shadow: none;
    }



}

@media only screen and (max-width: 768px) {
    /* For mobile phones */

    .appBarContainer {
        display: none;
    }
    ￼
    .link {
        font-size: var(--fontSizeMobile);
    }

    .contactContainer {
        padding-top: 2.5rem;
    }

    .contactTitle {
        font-size: 2.5rem;
        padding-bottom: 2.5rem;
    }

    .checkGallery {
        padding-top: 5rem;
    }

    .contactContent {
        flex-direction: column;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .contactText {
        width: 100%;
        padding-top: 0;
        padding-bottom: 2.5rem;
    }

    .gmaps {
        padding-top: 2.5rem;
        width: 100%;
    }

    .footer div {
        font-size: var(--fontSizeMobile);
    }
    
    .info {
        padding-left: 0;
        padding-right: 0;
    }

    #languageButton {
        width: 25%;
    }

    .about {
        width: 80%;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 1.5rem;
    }

    .burger {
        position: fixed;
        top: 2%;
        left: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 2rem;
        height: 2rem;
        background: transparent;
        border: 0;
        cursor: pointer;
        padding: 0;
        z-index: 15;
        animation: burger 6s forwards; 
    }

    .burger:focus {
        outline: none;
    }

    .burger div {
        width: 2rem;
        height: 0.25rem;
        background: var(--primaryColor);
        border-radius: 10px;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;
    }

    .burgerMenu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: rgba(0,0,0,0.8);
        text-align: left;
        padding: 2rem;
        position: fixed;
        transition: transform 0.3s ease-in-out;
        transform: translateX(-100%); 
        width: 30%
    }

    .burgerMenu a, link {
        font-size: 1.2rem;
        text-align: center;
        padding: 1.5rem 0;
        color: var(--primaryColor);
        transition: color 0.3s linear;     
  }

  a:hover, link:hover {
    font-weight: bold;
  }

  @keyframes burger {
    0% {

        opacity: 0;
    }

    50% {

        opacity: 0;
    }

    100% {
        opacity: 1;
    }
  }

  .entryAnimation {
      padding-top: 4rem;
      padding-bottom: 20rem;
  }

  .entryLogo {
    width: 80%;
    height: 100%;
}

.image-grid {
    gap: 6px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    grid-auto-rows: minmax(180px, auto);
}

.imgContainer:nth-child(5n){
    grid-column-end: span 2;
    height: 30vh;
  }

.image-grid2 {
    height: 10.3rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;

}

.image-grid3 {
    height: 10.3rem;
}

.image-grid4 {
    height: 10.3rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
}

.image2 {
    width: 100%;
    height: 90%;
}

.imageSide {
    width: 87%;
    height: 80%;
    align-self:center;
}

.imageSide1 {
    width: 100%;
    height: 80%;
    align-self: center;
}

.imageSide2 {
    width: 87%;
    height: 80%;
}

.imageSide3 {
    width: 100%;
    height: 80%;
}

.image:hover {
    transform: scale(1.5);
}

.img-nohover:hover {
    transform: none;
}

.checkGallery {
    padding-top: 4rem;
    padding-bottom: 0;
}

}
  


  